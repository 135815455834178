'use strict';

var scrollToElement = require('./scrollToElement');

$(document).on('click', '.show-links a', function (event) {
  var $link = $(this);
  var $maxContainer = $link.parent('.show-links');
  var $childsToSwitch = $maxContainer
    .find(
      '.over-max:not(.task-content),' +
      '.over-max-v'
    );
  var showless = $(this).hasClass('show-less');

  $maxContainer
    .toggleClass('open')
    .prev('table, ol, ul, .over-max-list, .js-truncated-content')
    .toggleClass('open');

  event.preventDefault();
  $childsToSwitch.toggleClass('over-max over-max-v');

  var doc = document.documentElement;
  var windowScrollPosition = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  var containerTop = $maxContainer.offset().top;

  if (showless && (containerTop < windowScrollPosition)) {
    return scrollToElement($maxContainer);
  }
});
