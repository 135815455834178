const { captureMessage } = require('../analytics/sentry');
const analytics = require('../analytics');
const $contactSupportBtn = $('.contact-support');
const $navbarCollapse = $('#top .header.navbar').find('.navbar-collapse');
const lang = window.language || 'en';

$(function () {
  const delay = 5000;
  let helpCenterRedirect = {};

  $contactSupportBtn.click(function (e) {
    e.preventDefault();
    e.stopPropagation();
    analytics.event('gtm-click-help');

    if (!$('body').hasClass('help-open')) {
      helpCenterRedirect = setTimeout(() => {
        captureMessage('Help dialog not triggered');
        window.location.href = `/${lang}/help`;
      }, delay);
      $contactSupportBtn.addClass('busy');
    }
  });

  window.addEventListener('gtm-help-opened', () => {
    clearTimeout(helpCenterRedirect);
    $contactSupportBtn.removeClass('busy');
    $('body').addClass('help-open');
    if ($('body').hasClass('navbar-open')) {
      $navbarCollapse.collapse('hide');
    }
  });

  window.addEventListener('gtm-help-closed', () => {
    $('body').removeClass('help-open');
  });
});
