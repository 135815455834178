'use strict';

import getPrice from './getPrice';
import activateToolTip from './activateTooltip';

let priceTimeout = null;
const validations = {
  vat: false,
  coupon: false
};

function updateForm (refreshPrice, validCoupon, validVat) {
  validations.vat = validVat;
  validations.coupon = validCoupon;

  if (refreshPrice) {
    clearTimeout(priceTimeout);
    priceTimeout = setTimeout(() => {
      getPrice(validCoupon, validVat);
    }, 300);
  }
  activateToolTip();
  updateSubmitButton();
}

function updateSubmitButton () {
  let formIsValid = true;
  const $billingForm = $('#billing-form-container');
  const country = $billingForm.find('#countries_select').val() || '';

  $billingForm.find('input:visible:not(.optional).js-apply-validation').each(function (index, element) {
    if (!$(this).hasClass('valid') || $(this).hasClass('invalid')) {
      formIsValid = false;
    }
  });

  if (country.length !== 2) {
    formIsValid = false;
  }

  $billingForm.find('.js-credit_submit').attr('disabled', !formIsValid);
  $billingForm.find('.js-paypal_submit').attr('disabled', !formIsValid);
}

module.exports = updateForm;
