'use strict';
var urlNode = require('url');

function isValidReviewUrl (url, { homepageOnly = false }) {
  const urlWithProtocol = /^https?:\/\//.test(url) ? url : `https://${url}`;
  try {
    const parsed = urlNode.parse(urlWithProtocol);
    if (!parsed.hostname.includes('.') || parsed.hostname.startsWith('.') || parsed.hostname.endsWith('.')) {
      return false;
    }
    if (homepageOnly) {
      return parsed.pathname === '/' && !parsed.search && !parsed.hash;
    } else {
      return true;
    }
  } catch (e) {
    return false;
  }
}

module.exports = isValidReviewUrl;
