const index = function () {
  const $body = $('body');

  $('.js-toggle-appnav').on('click', (e) => {
    e.preventDefault();
    toggleAppnav(!$body.hasClass('appnav-open'));
  });

  $('.app-backdrop').on('click', () => {
    toggleAppnav(!$body.hasClass('appnav-open'));
  });

  const toggleAppnav = (status) => {
    if (status) {
      $body.addClass('appnav-open');
    } else {
      $body.removeClass('appnav-open');
    }
  };
};

module.exports = index;
