'use strict';

var changePlanLink = $('#confirm-plan-change .confirm');
var baseHref = changePlanLink.attr('href');

$('.choose-plan').on('click', function (event) {
  var $this = $(this);
  var chosenPlan = $this.attr('href');
  var sendToRecurly = $this.data('target') === '#recurly-plan';

  chosenPlan = chosenPlan.replace('#', '');
  changePlanLink.attr('href', baseHref + chosenPlan);

  if (sendToRecurly) {
    event.preventDefault();
    var lang = window.wooGlobal.language;
    var url = '/' + lang + '/register/plan/' + chosenPlan;
    window.location = url;
  } else {
    return $('#confirm-plan-change .price').text(
      $this.parents('tr').find('.real_price').text()
    );
  }
});

$('#user-plan-content tr.ok td:not(.current):not(:first-child)')
  .on('click', function (event) {
    event.preventDefault();
    return $('#confirm-plan-change')
      .trigger('show-modal')
      .find('.price')
      .text($(this).text());
  });

$('#cancel-button.deal-period').on('click', function (event) {
  var dealModal;
  var $this = $(this);
  if ($this.hasClass('deal-period')) {
    event.preventDefault();
    dealModal = $('#deal-modal');
    $('#confirm-cancel').modal('hide');
    return dealModal.modal('show');
  }
});

$('#take-deal').on('click', function () {
  return $('#deal-modal').off('hidden');
});

$('#decline-deal').on('click', function () {
  $('#cancel-button.deal-period').removeClass('deal-period');
  return $('#confirm-cancel #cancel-button').trigger('click');
});
