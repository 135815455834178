'use strict';

function recaptchaResponseValidation (id) {
  var response = window.grecaptcha.getResponse(id);
  if (!response) {
    window.grecaptcha.execute(id);
    return;
  }
  return response;
}

module.exports = recaptchaResponseValidation;
