import updateForm from './updateForm';
import validateVATNumber from '../helpers/validateVATNumber';

const validCoupon = false;
const validVat = false;
const validation = {
  tos: (checked, next) => {
    if (!checked) {
      return next(false);
    }
    return next(true);
  },
  required: (value, next) => {
    if (value === null || value === '') {
      return next(false);
    }

    next(true);
  },
  creditCardNumber: (cardNumber, next) => {
    next(window.recurly.validate.cardNumber(cardNumber));
  },
  creditCardExpiration: (expirationMonth, expirationYear, next) => {
    next(window.recurly.validate.expiry(expirationMonth, expirationYear));
  },
  creditCardCVV: (cvv, next) => {
    next(window.recurly.validate.cvv(cvv));
  },
  vatCheck: (vat, next) => {
    return next(validateVATNumber(vat));
  },
  couponCheck: (couponCode, next) => {
    // No coupon always valid
    if (couponCode.length === 0) {
      return next(true);
    }

    const planCode = $('#billing-form-container').find('#plan_code').val();

    window.recurly.coupon({
      coupon: couponCode,
      plan: planCode
    }, (err, coupon) => next(!err));
  }
};

function handleValidation ($field, isValid) {
  const pricingFields = ['coupon_code', 'country_select', 'vat_field'];
  const refreshPrice = pricingFields.indexOf($field.attr('id')) !== -1;

  $field.toggleClass('invalid', !isValid)
    .toggleClass('valid', isValid)
    .parent()
    .find('.error')
    .toggleClass('hidden', isValid);

  updateForm(refreshPrice, validCoupon, validVat);
}

function validateFields ($validationField) {
  const validationType = $validationField.data('validation');
  let value = $validationField.val();
  if ($validationField.is('[type=checkbox]')) {
    value = $validationField[0].checked;
  }

  $validationField.removeClass('invalid')
    .parent()
    .find('.error')
    .addClass('hidden');

  if (typeof validationType !== 'undefined') {
    validation[validationType](value, isValid => {
      handleValidation($validationField, isValid);
    });
  }
}

module.exports = validateFields;
