'use strict';

function getElementTopOffset () {
  var headerOffset = 0;
  if (!$('body').hasClass('no-header')) {
    headerOffset = 80; // 60px fixed header + 20 dashboard margin
  }

  return Math.abs(headerOffset);
}

function scrollTo (id, speed, easing, callback) {
  var $element;
  var destination = 0;
  var $body = $('html, body');
  speed = speed || 0;

  if (typeof id === 'string') {
    $element = $(id);
  } else {
    $element = id;
  }

  if ($element.length) {
    var layoutOffset = getElementTopOffset();
    destination = $element.offset().top - layoutOffset;
    return $body.stop().animate({
      scrollTop: destination
    }, speed, easing, callback);
  }
}

module.exports = scrollTo;
