const analytics = require('../analytics');
const $reasonRadios = $('.list-group-item');
const feedback1Markup = $('.reason-1').remove().html();
const feedback2Markup = $('.reason-2').remove().html();
const feedback3Markup = $('.reason-3').remove().html();
const feedback4Markup = $('.reason-4').remove().html();
const feedback5Markup = $('.reason-5').remove().html();
const feedback6Markup = $('.reason-6').remove().html();
const feedback7Markup = $('.reason-7').remove().html();

function submitCancel () {
  $('.js-cancel-button').addClass('disabled').attr('disabled', 'disabled');
  $('.js-load-cancel').removeClass('hidden');
  $('.js-cancel-form').submit();
}

function reasonChanged () {
  $('.feedback').remove();
  $reasonRadios.removeClass('has-error');
  const $checkedRadio = $reasonRadios.find('input:checked');
  const reasonNumberChecked = $checkedRadio.val();
  switch (reasonNumberChecked) {
    case '1':
      $checkedRadio.closest('.list-group-item').after(feedback1Markup);
      break;
    case '2':
      $checkedRadio.closest('.list-group-item').after(feedback2Markup);
      break;
    case '3':
      $checkedRadio.closest('.list-group-item').after(feedback3Markup);
      break;
    case '4':
      $checkedRadio.closest('.list-group-item').after(feedback4Markup);
      break;
    case '5':
      $checkedRadio.closest('.list-group-item').after(feedback5Markup);
      break;
    case '6':
      $checkedRadio.closest('.list-group-item').after(feedback6Markup);
      break;
    case '7':
      $checkedRadio.closest('.list-group-item').after(feedback7Markup);
      break;
  }
}

function validateForm () {
  const $checkedRadio = $reasonRadios.find('input:checked');
  const reasonNumberChecked = $checkedRadio.val();
  if (reasonNumberChecked) {
    return true;
  } else {
    $reasonRadios.addClass('has-error');
  }
}

$reasonRadios.on('change', reasonChanged);

$('.js-cancel-button').on('click', event => {
  event.preventDefault();
  if (validateForm()) {
    analytics.event('myaccount-cancelreason-confirm-button', {});
    submitCancel();
  }
});
