const $navbar = $('#top .header.navbar');
const $dropdown = $navbar.find('.nav-item.dropdown');
const $dropdownToggle = $dropdown.find('.dropdown-toggle');
const $dropdownMenu = $dropdown.find('.dropdown-menu');
const $navbarCollapse = $navbar.find('.navbar-collapse');

$navbarCollapse.on('show.bs.collapse', () => {
  toggleCollapsedNavbar(true);
}).on('hide.bs.collapse', () => {
  toggleCollapsedNavbar(false);
});

function toggleCollapsedNavbar (status) {
  if (status) {
    $(window).scrollTop(0);
    $('body').addClass('navbar-open');
  } else {
    $('body').removeClass('navbar-open');
  }
  $('body').toggleClass('navbar-open', status);
}

$(document).on('click', event => {
  if (
    !$(event.target).parents('.navbar-header').length &&
    !$(event.target).parents('*[class^="js-"],*[class*=" js-"]').length &&
    $('body').hasClass('navbar-open')
  ) {
    $navbarCollapse.collapse('hide');
  }
});

$navbar.find('.navbar-toggle').on('click', event => {
  $navbarCollapse.collapse(!$('body').hasClass('navbar-open') ? 'show' : 'hide');
});

$(window).on('load resize', function () {
  $dropdown.click(function (e) {
    if (window.matchMedia('(min-width: 1100px)').matches) {
      e.stopPropagation();
    }
  });
  if (this.matchMedia('(min-width: 1100px)').matches) {
    $dropdown.hover(
      function () {
        const $this = $(this);
        $this.addClass('show');
        $this.find($dropdownToggle).attr('aria-expanded', 'true');
        $this.find($dropdownMenu).addClass('show');
      },
      function () {
        const $this = $(this);
        $this.removeClass('show');
        $this.find($dropdownToggle).attr('aria-expanded', 'false');
        $this.find($dropdownMenu).removeClass('show');
      }
    );
  } else {
    $dropdown.off('mouseenter mouseleave');
  }
});

$(window).on('resize', () => {
  if ($('body').hasClass('navbar-open')) {
    $navbarCollapse.collapse('hide');
  }
});
