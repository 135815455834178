'use strict';

var $trialCTA = $('.js-pricing-trial-cta');

$trialCTA.find('.js-trial-present').on('click', function (event) {
  if (!$trialCTA.hasClass('present-opened')) {
    event.preventDefault();
    $trialCTA.addClass('present-opened');
    // Wait for the animation end (see .illustration-container in css)
    return setTimeout(function () {
      window.location = $('.js-trial-btn').attr('href');
    }, 2000);
  }
});
