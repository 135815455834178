'use strict';

function simplePopover () {
  $('.simple-popover').popover({
    trigger: 'focus',
    container: 'body',
    html: true
  });
}

module.exports = simplePopover;
