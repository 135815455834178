'use strict';

// get a query GET params by name
function getParameterByName (name) {
  if (typeof name !== 'undefined') {
    name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regexS = '[\\?&]' + name + '=([^&#]*)';
    var regex = new RegExp(regexS);
    var results = regex.exec(window.location.search);
    if (typeof results === 'undefined' || results === null) {
      return '';
    }
    return decodeURIComponent(results[1].replace(/\+/g, ' '));
  }
  return false;
}

module.exports = getParameterByName;
