function activateToolTip () {
  return $('#billing-form-container').find('.field .error').tooltip({
    delay: {
      show: 400,
      hide: 100
    },
    title: function () {
      return $(this).text();
    }
  });
}

module.exports = activateToolTip;
