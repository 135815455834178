// GTM HELPERS
// ===========
//
// To track clicks:
// ----------------
//
//     <a href="/some-action" data-gtm-event-click="event-name">
//         Link
//     </a>
//
// To track modal events:
// ----------------------
//
//   Add a `data-gtm-event-modal` property to the modal markup
//
//     <div class="modal" data-gtm-event-modal="exit-page-modal">
//       ...
//     </div>
//
//   Will generate `exit-page-modal-open` and `exit-page-modal-close` events
//
// To add custom data to events:
// -----------------------------
//
//   Use query string format to add extra data
//   Use this only! when there's data specific to this event. Page level data doesn't belong here
//     <a href="/cta" data-gtm-event-click="some-cta" data-gtm-event-params="pos=top-button&url=cnn.com">
//         CTA
//     </a>
//     <a href="/cta" data-gtm-event-click="some-cta" data-gtm-event-params="pos=bottom-btn&url=cnn.com">
//         CTA
//     </a>
//

function parseQuery (query) {
  if (!query) {
    return {};
  }

  return Object.assign(
    {},
    ...query.split('&').map(pair => {
      const [name, value] = pair.split('=');
      return { [name]: decodeURIComponent(value) };
    })
  );
}

function pushEvent (eventName, eventParams) {
  window.dataLayer.push({ event: eventName, eventParams });
}

function handleClickEvent (event) {
  const { gtmEventClick, gtmEventParams } = event.currentTarget.dataset;
  pushEvent(gtmEventClick, parseQuery(gtmEventParams));
}

function createModalEventHandler (action) {
  return function handleModalEvent (event) {
    const { gtmEventModal, gtmEventParams } = event.currentTarget.dataset;
    pushEvent(`${gtmEventModal}-${action}`, parseQuery(gtmEventParams));
  };
}

function init () {
  $('body').on('click', '[data-gtm-event-click]', handleClickEvent);

  $('body').on('show.bs.modal', '[data-gtm-event-modal]', createModalEventHandler('open'));

  $('body').on('hide.bs.modal', '[data-gtm-event-modal]', createModalEventHandler('close'));
}

module.exports = {
  init,
  event: pushEvent
};
