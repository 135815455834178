'use strict';

function isEmptyField ($input) {
  if ($input && $input.length > 0) {
    return $input.val().trim().length < 1;
  }
  return false;
}

module.exports = isEmptyField;
