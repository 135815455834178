const moreInvoicesButton = $('#more-invoices');
const lessInvoicesButton = $('#less-invoices');
const showMax = 5;
const invoices = $('.invoice-link');

if (invoices.length > showMax) {
  invoices.slice(5).toggle();
  moreInvoicesButton.removeClass('hidden');
}

moreInvoicesButton.on('click', () => {
  invoices.slice(5).toggle();
  moreInvoicesButton.addClass('hidden');
  lessInvoicesButton.removeClass('hidden');
});

lessInvoicesButton.on('click', () => {
  invoices.slice(5).toggle();
  lessInvoicesButton.addClass('hidden');
  moreInvoicesButton.removeClass('hidden');
});
