'use strict';

window.onloadCaptcha = function () {
  $('.js-g-recaptcha').each(function (index, el) {
    var token;
    el.className.split(' ').forEach(function (value) {
      if (value.indexOf('js-grecaptcha-callback') > -1) {
        token = value;
      }
    });
    window[token] = window.grecaptcha.render(el, {
      sitekey: String(window.wooGlobal.settings.grecaptcha.sitekey),
      size: 'invisible',
      badge: 'inline',
      callback: function () {
        window.callbackCaptcha(token);
      }
    }, true);
  });
};

window.callbackCaptcha = function (token) {
  if (token === 'js-grecaptcha-callback-pricing-modal') {
    $('#create-ticket').submit();
  } else if (token === 'js-grecaptcha-callback-signup') {
    $('.js-login-form').submit();
  }
};

var gRecaptchaScript = document.createElement('script');

gRecaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=onloadCaptcha&render=explicit');

document.head.appendChild(gRecaptchaScript);
