'use strict';

const isEmptyField = require('../helpers/isEmptyField');
const isValidReviewUrl = require('../helpers/urlUtil');
const { captureMessage } = require('../analytics/sentry');
const $genbar = $('.generation-bar');
const $genbarInput = $genbar.find('input');

function init () {
  if ($genbar.length > 0) {
    $genbarInput.on('change paste focus', (e) => {
      const $form = $(e.target).parents('.generation-bar');
      const $input = $form.find('input');
      if ($form.hasClass('error')) {
        $form.removeClass('error');
        $input.removeClass('error');
      }
    });

    $genbarInput.on('change paste keyup focus blur', (e) => {
      const $form = $(e.target).parents('.generation-bar');
      const $input = $form.find('input');
      const $btn = $form.find('.generation-bar-btn');
      $btn.toggleClass('disabled', $input.val() === '');
    });

    $genbar.on('submit', function (event, params) {
      event.preventDefault();

      const $thisGenBar = $(this);

      let isInvalidRequest = false;
      params = params || '';

      if (!$thisGenBar.hasClass('busy')) {
        isInvalidRequest = genbarValidation($thisGenBar);
        if (isInvalidRequest) {
          return false;
        }

        $thisGenBar.addClass('busy');
        generateReviewForm($thisGenBar);
      }
    });
  }
}

// Returns TRUE if Empty or Invalid URL
function genbarValidation ($thisGenBar) {
  if ($thisGenBar.length > 0) {
    const $requiredInput = $thisGenBar.find('.js-validate-review-url');
    const $requiredInputValue = $requiredInput.val().trim();
    let isEmptyRequest = false;
    let isInvalidUrl = false;

    $thisGenBar
      .removeClass('error')
      .find('.error')
      .removeClass('error');

    if (isEmptyField($requiredInput)) {
      isEmptyRequest = true;
      $thisGenBar.addClass('error');
      $requiredInput.addClass('error');

      return isEmptyRequest;
    }

    if (!isValidReviewUrl($requiredInputValue, { homepageOnly: true })) {
      isInvalidUrl = true;
      $thisGenBar.addClass('error');
      $requiredInput.addClass('error');
      return isInvalidUrl;
    }
    return false;
  }
}

function generateReviewForm ($thisGenBar) {
  try {
    const $requiredInput = $thisGenBar.find('.js-validate-review-url');
    const $requiredInputValue = $requiredInput.val().trim();
    const url = $requiredInputValue;
    const action = $thisGenBar.attr('action');

    const $form = $(`<form method='post' action='${action}' style='visibility: hidden; display: none;'></form>`);
    $form.append(`<input type='hidden' name="url" value="${url}">`);
    $('body').append($form);
    $form.submit();
  } catch (error) {
    captureMessage('Genbar post error', {
      extra: {
        error: error
      }
    });
  }
}

module.exports = {
  init: init
};
