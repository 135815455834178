require('./trialCTA');

// initial page state setup
$('.js-load-plan').addClass('hidden');
$('.js-force-yearly').addClass('hidden');

[$('#paywallwelcome'), $('#billingFailed')]
  .forEach((element) => element !== undefined && element.modal('show'));

$('.js-billing-link').on('click', () => $('#billingFailed').modal('hide'));

function setDisplayYearly (showYearlyPrices) {
  window.location.hash = showYearlyPrices ? '#yearly' : '#monthly';
  $('.js-price-yearly').toggleClass('hidden', !showYearlyPrices);
  $('.js-plan-yearly').toggleClass('hidden', !showYearlyPrices);
  $('.js-price-monthly').toggleClass('hidden', showYearlyPrices);
  $('.js-plan-monthly').toggleClass('hidden', showYearlyPrices);
  $('.js-btn-yearly').toggleClass('hidden', !showYearlyPrices);
  $('.js-your-plan').toggleClass('hidden', showYearlyPrices);
  $('.js-tab-yearly').toggleClass('active', showYearlyPrices);
  $('.js-tab-monthly').toggleClass('active', !showYearlyPrices);
}

// switch between yearly monthly pricing
$('.js-switch-yearly').on('click', function () {
  setDisplayYearly(true);
});

$('.js-switch-monthly').on('click', function () {
  setDisplayYearly(false);
});

// skip paywall button
$('.js-skip').on('click', function (event) {
  event.preventDefault();
  $('form.js-skip-paywall').submit();
});

$('.js-updowngrade .js-choose-plan, .js-choosePlan .js-choose-plan').on('click', function (event) {
  event.preventDefault();
  const chosenLink = $(this).attr('href');
  const changePlanForm = $('.js-confirm-plan');
  const priceText = $(this).parents('.js-price-box').find('.js-price:visible').text();
  changePlanForm.attr('action', chosenLink);

  $('#confirm-plan-change .price').text(priceText);
  $('#confirm-plan-change').modal();
});

if (window.location.hash === '#yearly') {
  setDisplayYearly(true);
}
