const recaptchaResponseValidation = require('../g-recaptcha/recaptchaResponseValidation');
const getPhoneNumberInput = require('./intlTelPhone');

function createValidatedForm ($form, callback) {
  function lockForm () {
    var $inputs = $form.find('input');
    var $submit = $form.find('[type=submit]');

    $inputs.addClass('disabled').attr('disabled', 'disabled');
    $submit.addClass('busy');
  }

  function unlockForm () {
    var $inputs = $form.find('input');
    var $submit = $form.find('[type=submit]');

    $inputs.removeClass('disabled').attr('disabled', false);
    $submit.removeClass('busy');
  }

  function callbackAjax (json = {}) {
    if (json.errors) {
      unlockForm($form);
      handleValidationErrors(json, $form);
    } else {
      callback(json);
    }
  }

  function showFormError (message = null) {
    const $formError = $form.find('.error-message');
    $form.find('.alert')
      .attr('aria-hidden', 'false')
      .removeClass('hidden');
    if (!message) {
      message = $formError.data('defaultMessage');
    }
    $formError.html(message);
  }

  function handleValidationErrors (json) {
    window.grecaptcha.reset();
    $form
      .find('.form-group')
      .removeClass('has-error');

    for (const error of json.errors) {
      if (!error.field) {
        showFormError(error.message);
      }
      const $input = $form.find(`[name=${error.field}]`);
      $input.parents('.form-group')
        .addClass('has-error')
        .find('.error-msg')
        .html(error.message);
    }
  }

  function ajaxCall (url, params) {
    return $.ajax({
      url: url,
      data: params,
      type: 'POST',
      success: (res) => {
        callbackAjax(res);
      },
      error: (jqXHR, textStatus, errorThrown) => {
        if (jqXHR.responseJSON && jqXHR.responseJSON.errors) {
          handleValidationErrors(jqXHR.responseJSON);
        } else {
          showFormError();
        }
        unlockForm();
      }
    });
  }

  function handleLogin (event) {
    event.preventDefault();
    if ($form.find('.js-g-recaptcha').length > 0) {
      recaptchaResponseValidation(window['js-grecaptcha-callback-signup']);
    }

    if ($phoneNumberInput) {
      $form.find('#phone_number').val($phoneNumberInput.getNumber());
    }

    const formData = $form.serialize();
    lockForm($form);
    return ajaxCall($form.attr('action'), formData);
  }

  $form.on('submit', handleLogin);

  $form.find('.form-control').on('change blur click focus', function () {
    $(this).parents('.form-group')
      .removeClass('has-error')
      .find('.error-msg')
      .html('');
  });

  const $phoneNumberInput = ($form.find('.js-intl-tel-input').length > 0) && getPhoneNumberInput($form);
}

createValidatedForm($('.js-login-form'), ({ redirectTo }) => {
  window.location.href = redirectTo;
});

createValidatedForm($('.reset-password-form'), ({ redirectTo }) => {
  window.location.href = redirectTo;
});

createValidatedForm($('.forgot-password-form'), ({ redirectTo }) => {
  window.location.href = redirectTo;
});

createValidatedForm($('.user-password-form'), ({ redirectTo }) => {
  window.location.href = redirectTo;
});
