'use strict';

const { captureMessage } = require('../analytics/sentry');
var isEmptyField = require('../helpers/isEmptyField');
var $subUserForm = $('#sub-users-container .subuser-form');
var $subUserTable = $('.user-table');
var $subUserAlert = $subUserForm.find('.message');
var $deleteUserModal = $('#delete-user-modal');
var $upgradeButton = $('#upgrade-button');

function errorMessage (show, message) {
  message = message || '';

  $subUserAlert.toggleClass('hidden', !show).text(message);
}

function checkUpgradeButton () {
  var remainingProjects = parseInt(
    $upgradeButton.attr('data-remaining-projects'), 10
  );
  var subusersLeft = parseInt($upgradeButton.attr('data-subusers'), 10);

  $upgradeButton.toggleClass(
    'hidden', remainingProjects > 0 && subusersLeft > 0
  );
}

function ajaxAddRemoveUser ($context, data, deleting) {
  var originalData = data;

  $context.addClass('busy')
    .find('.js-input')
    .attr('disabled', 'disabled');

  $.ajax({
    type: 'POST',
    data: data,
    cache: false,
    url: $context.attr('action'),
    async: true,
    context: $context,
    dataType: 'json',
    success: function (json) {
      $context.removeClass('busy')
        .find('.js-input')
        .removeAttr('disabled')
        .val('');

      errorMessage(!json.status, json.message);
      $subUserForm.toggleClass('hidden', json.userRemaining < 1);
      $upgradeButton.attr('data-subusers', json.userRemaining);
      $('.multiuser-available').text(json.userRemaining);

      if (json.status === 1) {
        if (deleting) {
          removeUserInList(originalData.email);
        } else {
          userWaitingStatus(json.newUser, true);
        }
      }

      checkUpgradeButton();
    },
    error: function (jqXHR, textStatus, errorThrown) {
      captureMessage('Subusers remove user error', {
        extra: {
          textStatus: textStatus,
          errorThrown: errorThrown,
          currentWebsite: window.currentWebsite
        }
      });

      return $('.ajax-error-message').removeClass('hidden');
    }
  });
}

function userWaitingStatus (user, removeWaiting) {
  var $userLine;

  if (user) {
    $userLine = $subUserTable
      .find('[data-user="' + user + '"]')
      .parents('.user-list-item');

    if ($userLine.length < 1 && !removeWaiting) {
      $userLine = addUserInList(user);
    }

    $userLine.toggleClass('waiting', !removeWaiting);
  }
}

function removeUserInList (user) {
  if (user) {
    $subUserTable.find('[data-user="' + user + '"]')
      .parents('.user-list-item')
      .remove();
  }
}

function addUserInList (user) {
  var $lastItem = $subUserTable.find('.user-list-item:last:not(.hidden)');
  var $newItem = $lastItem.clone();

  $newItem.find('.js-remove-user').attr('data-user', user);
  $newItem.find('.js-col-user').text(user);
  $newItem.find('.js-col-number').text('0');
  $lastItem.after($newItem);

  return $newItem;
}

// Add sub user
$subUserForm.on('submit', function (event) {
  var $form = $(this);
  var $input = $form.find('.js-input');
  var value = $input.val().trim();

  if (isEmptyField($input) || $form.hasClass('busy')) {
    return false;
  }

  event.preventDefault();
  userWaitingStatus(value);
  ajaxAddRemoveUser($form, $form.serialize());
});

// Set the user email to remove
$subUserTable.find('.user-list').on('click', '.remove-user', function () {
  var user = $(this).attr('data-user');

  $deleteUserModal.attr('data-user', user);
});

// Remove sub user
$deleteUserModal.on('click', '.submit', function (event) {
  var $modal = $(event.delegateTarget);
  var userToRemove = $modal.attr('data-user');
  var data = {
    deleting: 1,
    email: userToRemove
  };
  var deleting = true;

  event.preventDefault();
  $modal.modal('hide');
  userWaitingStatus(userToRemove);
  ajaxAddRemoveUser($subUserForm, data, deleting);
});
