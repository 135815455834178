// European VAT number RegExp
// https://stackoverflow.com/questions/33625770

'use strict';

var reVAT = new RegExp(
  '^(' +
  '(AT)?U[0-9]{8}|' + // Austria
  '(BE)?0[0-9]{9}|' + // Belgium
  '(BG)?[0-9]{9,10}|' + // Bulgaria
  '(HR)?[0-9]{11}|' + // Croatia
  '(CY)?[0-9]{8}[A-Z]|' + // Cyprus
  '(CZ)?[0-9]{8,10}|' + // Czech Republic
  '(DE)?[0-9]{9}|' + // Germany
  '(DK)?[0-9]{8}|' + // Denmark
  '(EE)?[0-9]{9}|' + // Estonia
  '(EL|GR)?[0-9]{9}|' + // Greece
  'ES(?:[A-Z]|[0-9])[0-9]{7,8}(?:[0-9]|[A-Z])|' + // Spain
  '(FI)?[0-9]{8}|' + // Finland
  '(FR)?[0-9A-HJ-NP-Z]{2}[0-9]{9}|' + // France
  '(GB)?([0-9]{9}([0-9]{3})?|[A-Z]{2}[0-9]{3})|' + // United Kingdom
  '(HU)?[0-9]{8}|' + // Hungary
  '(IE)?([0-9]{7,8}[A-Z]|[0-9][A-Z][0-9]{5,6}[A-Z]|[0-9]{6,7}[A-Z]{2})|' + // Ireland
  '(IT)?[0-9]{11}|' + // Italy
  '(LT)?([0-9]{9}|[0-9]{12})|' + // Lithuania
  '(LU)?[0-9]{8}|' + // Luxembourg
  '(LV)?[0-9]{11}|' + // Latvia
  '(MT)?[0-9]{8}|' + // Malta
  '(NL)?([0-9]{9}B[0-9]{2}|[0-9]{9}BO[0-9])|' + // Netherlands
  '(PL)?[0-9]{10}|' + // Poland
  '(PT)?[0-9]{9}|' + // Portugal
  '(RO)?[0-9]{2,10}|' + // Romania
  '(SE)?[0-9]{12}|' + // Sweden
  '(SI)?[0-9]{8}|' + // Slovenia
  '(SK)?[0-9]{10}' + // Slovakia
  ')$'
);

function validateVATNumber (vat) {
  if (vat) {
    const normalizedVATNumber = vat.toUpperCase().replace(/ |-|_|\./g, '');
    return reVAT.test(normalizedVATNumber);
  }
  return false;
}

module.exports = validateVATNumber;
