'use strict';

$(function () {
  $('[data-toggle="popover"]').popover({
    delay: {
      show: 400,
      hide: 100
    },
    trigger: 'hover',
    content: function () {
      return $(this).next('.popover-data').html();
    }
  });
});
