function preloadImage (img) {
  img.src = img.dataset.src;
}

let observer = null;

if (window.IntersectionObserver) {
  observer = new window.IntersectionObserver((entries, self) => {
    for (const entry of entries) {
      if (entry.isIntersecting) {
        preloadImage(entry.target);
        self.unobserve(entry.target);
      }
    }
  }, {
    rootMargin: '100px'
  });
}

function initLazyImages ({ loadImmediately = false } = {}) {
  const lazyImages = document.querySelectorAll('.lazy[data-src]');
  for (let i = 0; i < lazyImages.length; i++) {
    const lazyImg = lazyImages[i];
    const mustLoadLazily = !loadImmediately && observer;
    if (mustLoadLazily) {
      observer.observe(lazyImg);
    } else {
      preloadImage(lazyImg);
    }
    lazyImg.classList.remove('lazy');
  }
}

module.exports = {
  initLazyImages
};
