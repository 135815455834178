import validateVATNumber from '../helpers/validateVATNumber';
import euCountries from './euCountries';

const $billingForm = $('#billing-form-container');

const $countryField = $billingForm.find('#countries_select');
const $vatField = $billingForm.find('#vat_field');
const $vatContainer = $billingForm.find('.add-vat-group');
const updateBillingDetailsText = $('#update_billing_details').text();
const pleaseFillFormText = $('#billing_details_please_fill_info').text();

$billingForm.find('#want-address').click();

function displayVatFieldsBasedOnCountry () {
  if (euCountries.includes($countryField.val())) {
    $vatContainer.removeClass('hidden').collapse('show');
    if ($vatField.val().length > 0) {
      $billingForm.find('.vat_number').collapse('show');
    }
  } else {
    $vatContainer.addClass('hidden').collapse('hide');
    $billingForm.find('.vat_number').collapse('hide');
    $vatField.val('');
  }
}

displayVatFieldsBasedOnCountry();
// Hide or show vat on country changed
$countryField.change(function () {
  displayVatFieldsBasedOnCountry();
});

// Validate first name last name and country for sending form
$billingForm.find('.required').on('keyup change', function () {
  const firstNameValue = $('#first_name_field').val() || '';
  const lastNameValue = $('#last_name_field').val() || '';
  const zipCodeValue = $('#postal_code').val() || '';
  const countryValue = $countryField.val() || '';
  const isCountryValid = countryValue.length > 0;
  const isFirstNameValid = firstNameValue.trim().length > 0;
  const isLastNameValid = lastNameValue.trim().length > 0;
  const isZipCodeValid = zipCodeValue.trim().length > 0;
  const formIsValid = isFirstNameValid && isLastNameValid && isCountryValid;

  $('#first_name_field')
    .toggleClass('invalid', !isFirstNameValid)
    .next('.error')
    .toggleClass('hidden', isFirstNameValid);

  $('#last_name_field')
    .toggleClass('invalid', !isLastNameValid)
    .next('.error')
    .toggleClass('hidden', isLastNameValid);

  $('#postal_code')
    .toggleClass('invalid', !isZipCodeValid)
    .next('.error')
    .toggleClass('hidden', isZipCodeValid);

  updateSubmitButton(formIsValid);
});

// Validating vat field
$vatField.keyup(function () {
  const vat = $vatField.val();
  const isValid = validateVATNumber(vat);
  updateVatField(isValid);
  updateSubmitButton(isValid);
});

function updateVatField (isValid) {
  $billingForm.find('#vat_field')
    .toggleClass('invalid', !isValid)
    .toggleClass('valid', isValid)
    .parent()
    .find('.error')
    .toggleClass('hidden', isValid);
}

function updateSubmitButton (isValid) {
  const submitText = isValid ? updateBillingDetailsText : pleaseFillFormText;
  $billingForm.find('.js-billing-details_submit')
    .attr('disabled', !isValid)
    .text(submitText);
}
