'use strict';

const sentry = require('./analytics/sentry');
sentry.init();

const $ = window.jQuery;
const analytics = require('./analytics');
const genbar = require('./genbar/generationBar');
const simpleTooltip = require('./helpers/simpleTooltip');
const appNavigation = require('./helpers/appNavigation');
const $body = $('body');
const simplePopover = require('./helpers/simplePopover');
const { initLazyImages } = require('./helpers/lazyImages');

// Analytics
analytics.init();

// Boostrap JS extended
require('./bootstrap-extended/animations');
require('./bootstrap-extended/tooltip-closer');

genbar.init();

appNavigation();

window.isMobile = window.isMobile || false;

// fix cors for ie ?
$.support.cors = true;

if (typeof window.moment !== 'undefined') {
  window.moment.locale(window.wooGlobal.language);
}

initLazyImages({
  loadImmediately: $body.hasClass('pdf')
});

// Jquery Plugins
require('./jquery-plugins/truncate');
require('./jquery-plugins/formatTime');
require('./jquery-plugins/prettyTextOverflow');

// Helpers
require('./helpers/topNavigationBar');
require('./helpers/validateForm');
require('./helpers/toggleAccordion');
require('./helpers/wooPopover');
require('./helpers/contactSupport');
require('./g-recaptcha/recaptchaOnload');

if ($body.hasClass('user')) {
  require('./user/cancelSubscription');
  require('./user/toggleInvoices');
  require('./login/login');
}

if ($body.hasClass('subusers')) {
  require('./subusers/subusers');
}

if ($body.hasClass('pricing')) {
  require('./pricing/pricing');
}

if ($body.hasClass('login')) {
  require('./login/login');
  require('./login/intlTelPhone');
}

if ($body.hasClass('forgot-password')) {
  require('./login/login');
}

if ($body.hasClass('register')) {
  require('./register/register');
}

if ($body.hasClass('update-billing-details')) {
  require('./register/updateBillingDetails');
}

if ($body.hasClass('plans')) {
  require('./plans/plans');
}

if ($body.hasClass('extension')) {
  require('./helpers/extensionLink');
}

simpleTooltip();
simplePopover();

require('./helpers/jsLoadEnd');
