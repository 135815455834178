'use strict';

const Sentry = require('@sentry/browser');

function init () {
  Sentry.init({
    dsn: $('body').hasClass('pdf') ? false : window.wooGlobal.settings.sentryDsn,
    release: window.wooGlobal.settings.sentryRelease,
    whitelistUrls: [
      /^https:\/\/(www\.)?woorank\.com/,
      window.wooGlobal.settings.cdn
    ]
  });

  Sentry.setUser({
    id: window.wooGlobal.user.id
  });
}

function capture (method, msg, options = {}) {
  Sentry.withScope(scope => {
    Object.entries(options.tags || {})
      .forEach(([key, value]) => scope.setTag(key, value));
    Object.entries(options.extra || {})
      .forEach(([key, value]) => scope.setExtra(key, value));
    method(msg);
  });
}

function captureMessage (msg, options = {}) {
  return capture(Sentry.captureMessage, msg, options);
}

function captureException (msg, options = {}) {
  return capture(Sentry.captureException, msg, options);
}

module.exports.init = init;
module.exports.captureMessage = captureMessage;
module.exports.captureException = captureException;
