'use strict';

var scrollTo = require('./scrollTo');

function scrollToElement ($element, speed, callback) {
  speed = speed || 500;
  if ($element.length) {
    return scrollTo($element, speed, callback);
  }
}

module.exports = scrollToElement;
