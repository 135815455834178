'use strict';

const intlTelInput = require('intl-tel-input/build/js/intlTelInput');
const intlTelInputUtils = require('intl-tel-input/build/js/utils');

function getPhoneNumberInput ($form) {
  const $intTelInputElem = $form.find('.js-intl-tel-input');

  const intlTelInputInstance = intlTelInput($intTelInputElem.get(0), {
    customContainer: 'd-flex',
    separateDialCode: true,
    customPlaceholder: () => (''),
    preferredCountries: ['us'],
    utilsScript: intlTelInputUtils
  });

  return intlTelInputInstance;
}

module.exports = getPhoneNumberInput;
