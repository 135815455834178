(function () {
  function replaceExtensionLinks () {
    const isFirefox = typeof InstallTrigger !== 'undefined';
    const isIE = /* @cc_on!@ */false || !!document.documentMode;
    const isEdge = !isIE && !!window.StyleMedia;
    const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    const isEdgeChromium = isChrome && (navigator.userAgent.indexOf('Edg') !== -1);

    const urls = {
      firefox: 'https://addons.mozilla.org/en-US/firefox/addon/seo-website-analysis/',
      edge: 'https://chrome.google.com/webstore/detail/seo-analysis-website-revi/hlngmmdolgbdnnimbmblfhhndibdipaf',
      chrome: 'https://chrome.google.com/webstore/detail/seo-analysis-website-revi/hlngmmdolgbdnnimbmblfhhndibdipaf'
    };
    let link = urls.chrome;
    if (isFirefox) {
      link = urls.firefox;
    }
    if (isEdge || isEdgeChromium) {
      link = urls.edge;
    }

    document.querySelectorAll('.js-extension-link').forEach(v => v.setAttribute('href', link));
  }

  function init () {
    replaceExtensionLinks();
  }

  document.addEventListener('DOMContentLoaded', init);
})();
