'use strict';

$('form.to-validate').on('submit', function (event) {
  var $form = $(this);
  var $wooReq = $form.find('input.required');
  var woosendForm = true;
  var inputErrorCounter = 0;

  // reset
  $form.removeClass('error');
  $wooReq.removeClass('error');

  // checking
  $wooReq.each(function () {
    var $this = $(this);
    var inputValTrim = $this.val().trim();

    if (inputValTrim.length < 1) {
      inputErrorCounter++;
    }

    if (inputErrorCounter > 0) {
      return $this.addClass('error');
    }
  });

  if (inputErrorCounter > 0) {
    $form.addClass('error');
    woosendForm = false;
  }

  if (woosendForm === !true) {
    return event.preventDefault();
  }
});

$('form.to-validate').on('keyup', 'input.required', function (event) {
  var $input = $(this);
  var $form = $(event.delegateTarget);
  var formValid = $input.val().trim().length < 1;

  $form.toggleClass('error', formValid);
  $input.toggleClass('error', formValid);
});

$('form.to-validate').on('blur', 'input.required', function (event) {
  var $input = $(this);
  var $form = $(event.delegateTarget);

  $input.removeClass('error');
  $form.removeClass('error');
});
