const { captureMessage } = require('../analytics/sentry');

const registration = {
  logTokenizationErrors: (source, err, $form) => {
    captureMessage('Failed to obtain recurly token', {
      tags: {
        event: 'recurly-tokenize',
        source
      },
      extra: {
        firstName: $('[data-recurly="first_name"]', $form).val(),
        lastName: $('[data-recurly="last_name"]', $form).val(),
        country: $('[data-recurly="country"]', $form).val(),
        address1: $('[data-recurly="address1"]', $form).val(),
        postalCode: $('[data-recurly="postal_code"]', $form).val(),
        state: $('[data-recurly="state"]', $form).val(),
        city: $('[data-recurly="city"]', $form).val(),
        vatNumber: $('[data-recurly="vat_number"]', $form).val(),

        message: err.message,
        errorCode: err.code,
        errorFields: err.fields
      }
    });
  },
  logSubscriptionErrors: (source, err, $form) => {
    captureMessage('Failed to create recurly subscription', {
      tags: {
        event: 'recurly-subscribe',
        source
      },
      extra: {
        firstName: $('[data-recurly="first_name"]', $form).val(),
        lastName: $('[data-recurly="last_name"]', $form).val(),
        country: $('[data-recurly="country"]', $form).val(),
        address1: $('[data-recurly="address1"]', $form).val(),
        postalCode: $('[data-recurly="postal_code"]', $form).val(),
        state: $('[data-recurly="state"]', $form).val(),
        city: $('[data-recurly="city"]', $form).val(),
        vatNumber: $('[data-recurly="vat_number"]', $form).val(),

        message: err.message,
        errorCode: err.code,
        errorFields: err.fields
      }
    });
  },
  updatePaymentForm: function (fields) {
    $('.cc-field[data-recurly]').removeClass('has-error');
    fields.forEach(i => {
      $('.cc-field[data-recurly="' + i + '"]').addClass('has-error');
    });
  },
  recurlyToken: function ($form) {
    const planCode = $form.find('#plan_code').val();
    const isAddBilling = $form.find('#is_add_billing').val();
    const isUpdateBilling = $form.find('#is_update_billing').val();

    if (planCode.includes('cft') && !isAddBilling && !isUpdateBilling) {
      return registration.callSubscribeOnFront($form, { id: null });
    }

    window.recurly.token($form[0], (err, token) => {
      if (err) {
        $('#server_errors_display').removeClass('hidden');
        registration.updatePaymentForm(err.fields);
        $('#server_errors_text').html($('#server_errors_text_i18n').val());
        $form
          .find('.js-credit_submit')
          .attr('disabled', false)
          .text(registration.getSubmitText());

        registration.logTokenizationErrors('credit-card', err, $form);
      } else {
        registration.callSubscribeOnFront($form, token);
      }
    });
  },
  recurlyRisk: function ($form, actionToken, dataToSend) {
    const risk = window.recurly.Risk();
    const threeDSecure = risk.ThreeDSecure({ actionTokenId: actionToken });
    const threeDSecurePanel = $('#three-d-secure-panel');

    threeDSecurePanel.removeClass('hidden');
    threeDSecurePanel.html('');
    threeDSecure.attach(threeDSecurePanel[0]);

    threeDSecure.on('error', err => {
      threeDSecurePanel.addClass('hidden');
      $('#server_errors_display').removeClass('hidden');
      threeDSecurePanel.addClass('hidden');
      $('#server_errors_text').html(err.message);
      $form.find('.js-credit_submit')
        .attr('disabled', false)
        .text(registration.getSubmitText());
    });

    threeDSecure.on('token', actionResultToken => {
      if ($('#server_errors_display').text().trim() !== '') {
        $('#server_errors_display').removeClass('hidden');
      }
      threeDSecurePanel.addClass('hidden');
      dataToSend.from_three_d_token_id = actionToken;
      dataToSend.three_d_secure_action_result_token_id = actionResultToken.id;
      registration.submitPaymentToRecurly($form, dataToSend);
    });
  },
  paypalRecurlyToken: function ($form) {
    const options = {
      description: 'Woorank Subscription',
      country: $form.find('#countries_select').val()
    };

    $form.addClass('hidden');
    $('#waiting_for_paypal').removeClass('hidden');

    window.recurly.paypal(options, (err, token) => {
      if (err) {
        $('#server_errors_display').removeClass('hidden');
        $('#server_errors_text').html(err.message);
        $form.removeClass('hidden');
        $('#waiting_for_paypal').removeClass('hidden');

        registration.logTokenizationErrors('paypal', err, $form);
      } else {
        registration.callSubscribeOnFront($form, token);
      }
    });
  },
  callSubscribeOnFront: function ($form, token) {
    const planCode = $form.find('#plan_code').val();
    const couponCode = $form.find('#coupon_code').val();
    const accountCode = $form.find('#account_code').val();
    const email = $form.find('#email_field').val();
    const firstName = $form.find('#first_name_field').val();
    const lastName = $form.find('#last_name_field').val();
    const companyName = $form.find('#company_name_field').val();
    const countryCode = $form.find('#countries_select').val();
    const vatNumber = $form.find('#vat_field').val();
    const currency = $form.find('#currency').val();
    const creditCard = $form
      .find('.card_option')
      .hasClass('selected') ? 1 : 0;
    const dataToSend = {
      plan_code: planCode,
      coupon_code: couponCode,
      account_code: accountCode,
      email: email,
      first_name: firstName,
      last_name: lastName,
      company_name: companyName,
      country_code: countryCode,
      vat_number: vatNumber,
      credit_card: creditCard,
      recurly_token: token.id,
      currency: currency,
      three_d_secure_action_result_token_id: null
    };
    registration.submitPaymentToRecurly($form, dataToSend);
  },
  submitPaymentToRecurly: ($form, dataToSend) => {
    const lang = window.wooGlobal.language;
    const path = '/' + lang + '/recurly/subscribe';

    $.post(path, dataToSend, function (response) {
      if (!response.data) {
        if (response.error) {
          registration.logSubscriptionErrors(dataToSend.credit_card ? 'credit-card' : 'paypal', {
            message: response.error
          }, $form);
        }
        return;
      }

      if (response.data.success === true) {
        window.location = $form.attr('action');
      } else {
        const actionToken = response.data.errors && response.data.errors.find(error => error.three_d_secure_action_token_id);
        if (actionToken) {
          registration.recurlyRisk($form, actionToken.three_d_secure_action_token_id, dataToSend);
        } else {
          $('#server_errors_display').removeClass('hidden');
          $('#server_errors_text').html(response.data.validationErrors);
          $form.find('.js-credit_submit')
            .attr('disabled', false)
            .text(registration.getSubmitText());
          registration.logSubscriptionErrors(dataToSend.credit_card ? 'credit-card' : 'paypal', {
            message: response.data.validationErrors
          }, $form);
        }
      }
    });
  },
  getSubmitText: () => {
    const isUpdatePayment = window.location.pathname.match('updatepayment') !== null;
    const isAddBilling = window.location.pathname.match('addbilling') !== null;
    let submitText = $('#start_free_trial').text();
    if (isUpdatePayment) {
      submitText = $('#update_payment_method').text();
    }
    if (isAddBilling) {
      submitText = $('#add_billing').text();
    }

    return submitText;
  }
};

module.exports = registration;
