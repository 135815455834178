'use strict';

$.fn.extend({
  formatTime: function (options) {
    var toFormat = [];

    this.find('.js-format-time').each(function () {
      var $this = $(this);
      var datetimeString =
        $this.attr('datetime') ||
        $this.data('datetime') ||
        $this.text();
      var format = $this.data('format') || 'LLL';
      var formatted = window.moment(datetimeString).format(format);

      toFormat.push({
        $elm: $this,
        formatted: formatted
      });
    });
    // do update in a single batch to avoid layout trashing
    toFormat.forEach(function (obj) {
      obj.$elm.text(obj.formatted).removeClass('js-format-time');
    });
  }
});

// Apply format time
(function () {
  $('body').formatTime();
})();
