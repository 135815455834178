import euCountries from './euCountries';
// Init the pricing module of recurly
const pricing = window.recurly.Pricing();

function getPrice (validCoupon, validVat) {
  const $billingForm = $('#billing-form-container');
  const subsciptionCode = $billingForm.find('#plan_code').val();
  const countryCode = $billingForm.find('#countries_select').val();
  const vatNumber = $billingForm.find('#vat_field').val();
  const vatCleaned = vatNumber.toUpperCase().replace(/ |-|_|\./g, '');
  let couponCode = null;

  if (euCountries.indexOf($('#countries_select').val()) < 0) {
    $billingForm.find('#plus_tva').addClass('hidden');
    $billingForm.find('.add-vat-group').addClass('hidden').collapse('hide');
    $billingForm.find('.vat_number').collapse('hide');
  } else {
    $billingForm.find('.add-vat-group').removeClass('hidden');

    if (validVat === false) {
      $billingForm.find('#plus_tva').removeClass('hidden');
    } else {
      $billingForm.find('#plus_tva').addClass('hidden');
    }
  }

  if (validCoupon) {
    couponCode = $billingForm.find('#coupon_code').val();
  } else {
    couponCode = '';
  }

  pricing
    .plan(subsciptionCode, {
      quantity: 1
    })
    .currency('USD')
    .address({
      country: countryCode
    })
    .coupon(couponCode)
    .tax({
      tax_code: 'digital',
      vat_number: vatCleaned
    })
    .done(price => {
      $billingForm.find('#plan_price').html(price.next.subtotal);
    });
}

module.exports = getPrice;
