'use strict';
function screenXl () {
  return window.innerWidth > 1079;
}

function screenLg () {
  return window.innerWidth > 991;
}

function screenMd () {
  return window.innerWidth > 767;
}

function screenSm () {
  return window.innerWidth > 425;
}

function screenXs () {
  return window.innerWidth > 375;
}

module.exports = {
  isDesktopLarge: screenXl,
  isDesktop: screenLg,
  isTablet: screenMd,
  isMobile: screenSm,
  isMobileSmall: screenXs
};
