import getParameterByName from '../helpers/getParameterByName';
import registration from './registration';
import validateFields from './validateFields';
import updateForm from './updateForm';

const $billingForm = $('#billing-form-container');

// Init recurly cith the public API Key
const publicKey = $('#recurly-public-api-key').val();

// Validation Timeouts
let timeout = null;

window.recurly.configure({
  publicKey,
  required: ['cvv']
});

const isUpdatePayment = window.location.pathname.match('updatepayment') !== null;

$billingForm.find('.js-credit_submit').attr('disabled', true);
$billingForm.find('.js-paypal_submit').attr('disabled', true);

$billingForm
  .find('.payment_option')
  .on('click', function () {
    if ($(this).hasClass('card_option')) {
      $billingForm
        .find('.js-credit_submit')
        .removeClass('hidden');

      $billingForm
        .find('#paypal_submit')
        .addClass('hidden');

      $billingForm
        .find('.payment_option')
        .toggleClass('selected');
    } else {
      $billingForm
        .find('.js-credit_submit')
        .addClass('hidden');

      $billingForm
        .find('#paypal_submit')
        .removeClass('hidden');

      $billingForm
        .find('.payment_option')
        .toggleClass('selected');
    }
  });

$billingForm
  .find('#billing-form')
  .submit(function (event) {
    const $form = $(this);

    event.preventDefault();
    if ($billingForm.find('#countries_select').val().length > 0) {
      $billingForm
        .find('#countries_select')
        .parent()
        .removeClass('invalid');

      $form
        .find('.js-credit_submit')
        .attr('disabled', true)
        .text($('#please_wait').text());

      $form
        .find('.error')
        .addClass('hidden');

      registration.recurlyToken($form);
    } else {
      $billingForm
        .find('#countries_select')
        .parent()
        .addClass('invalid');
    }
  });

$billingForm.find('.js-terms-and-privacy input[type="checkbox"]').on('change', function () {
  $('.js-terms-and-privacy').toggleClass('has-error', !this.checked);
  validateFields($(this), $billingForm);
});

$('#paypal_submit').on('click', function () {
  const $form = $billingForm.find('#billing-form');
  const firstNameValue = $('#first_name_field').val() || '';
  const lastNameValue = $('#last_name_field').val() || '';
  const isFirstNameValid = firstNameValue.trim().length > 0;
  const isLastNameValid = lastNameValue.trim().length > 0;

  $('#first_name_field')
    .toggleClass('invalid', !isFirstNameValid)
    .next('.error')
    .toggleClass('hidden', isFirstNameValid);

  $('#last_name_field')
    .toggleClass('invalid', !isLastNameValid)
    .next('.error')
    .toggleClass('hidden', isFirstNameValid);

  if (isFirstNameValid && isLastNameValid) {
    registration.paypalRecurlyToken($form);
  }
});

$billingForm.find('input').on('keyup', function (event) {
  if (event.which !== 9) {
    validateFields($(this), $billingForm);
  }
});

$billingForm.find('input').on('keydown', function (event) {
  if (event.which === 9) {
    validateFields($(this), $billingForm);
  }
});

$billingForm.find('input').on('change', function (event) {
  validateFields($(this), $billingForm);
});

$billingForm.find('#want-vat').click(function (event) {
  event.preventDefault();
  if (!$billingForm.find('#want-vat-container').hasClass('collapsing')) {
    $billingForm
      .find('#vat_number_container')
      .removeClass('vat_number_padding');

    $billingForm
      .find('#vat_field')
      .addClass('valid');

    updateForm(true);
  }
});

$billingForm.find('#vat_field').keyup(function () {
  const vatField = $billingForm.find('#vat_field');

  if (vatField.val().length > 0) {
    $billingForm
      .find('.js-credit_submit')
      .attr('disabled', true)
      .text($('#please_wait').text());
  }
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    if (vatField.val().length > 0) {
      $billingForm
        .find('.js-credit_submit')
        .attr('disabled', true)
        .text($('#please_wait').text());

      validateFields(vatField);
    } else {
      $billingForm
        .find('#plus_tva')
        .removeClass('hidden');

      vatField
        .removeClass('invalid')
        .addClass('valid');

      $billingForm
        .find('.vat_number > .error')
        .addClass('hidden');

      updateForm(true);
    }
  }, 500);
});

$billingForm.find('#countries_select').on('change', function () {
  const country = $(this).val() || '';
  const isValid = country.length === 2;

  $billingForm
    .find('#countries_select')
    .parent()
    .toggleClass('invalid', !isValid);

  updateForm(true);
});

const countryFromFront = $billingForm
  .find('#locale-country')
  .val()
  .toUpperCase();

const countriesField = $billingForm
  .find('#countries_select');

if (countryFromFront !== '-') {
  countriesField.val(countryFromFront);
}

if (countriesField.val() === null) {
  countriesField.val(-1);
}
if (countriesField.val()) {
  if (countriesField.val().length !== 2) {
    $billingForm
      .find('#countries_select')
      .parent()
      .addClass('invalid');
  } else {
    $billingForm
      .find('#countries_select')
      .parent()
      .removeClass('invalid');
  }
}

// To update with Cookie Logic
const coupon = getParameterByName('cpc') ||
  $billingForm.find('#coupon_code').val();

if (coupon !== '') {
  // Validation already calls updateForm
  validateFields($billingForm.find('#coupon_code'));
} else {
  updateForm(true);
}

$('.billing_info .collapse').on('hidden.bs.collapse', function () {
  $('input', this)
    .val('')
    .removeClass('invalid');
});

if (isUpdatePayment) {
  $('#want-vat').click();
  $('#want-address').click();
  $('.js-contact_info').addClass('hidden');
}
updateForm(true);
