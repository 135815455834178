'use strict';

$.fn.extend({
  truncate: function (options) {
    return this.each(function () {
      // Only show buttons on containers that are actually truncated
      $(this).find('.js-truncated-content').each(function () {
        var $this = $(this);
        var containerHeight = $this.height() || 0;
        var contentHeight = $this.find('> div').height() || 0;
        var $buttons = $this.next('.js-truncate-btns');
        if (contentHeight > containerHeight) {
          $buttons.show();
        } else {
          $buttons.hide();
        }
      });
    });
  }
});
