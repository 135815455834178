'use strict';

$.fn.extend({
  prettyTextOverflow: function () {
    this.each(function (i, elm) {
      var $elm = $(elm);
      var isOverflowing = elm.scrollWidth > $elm.innerWidth();
      var text;

      if (isOverflowing) {
        text = $elm.text().trim();
        $elm.attr('title', text);
        $elm.tooltip();
      }
    });
  }
});
