'use strict';

import { isTablet } from '../helpers/responsiveViewport';

function getTooltipOptions () {
  return (isTablet()) ? {} : 'destroy';
}

$(window).resize(function () {
  simpleTooltip();
});

function simpleTooltip () {
  $.fn.tooltip && $('.simple-tooltip').tooltip(getTooltipOptions())
    .on('click', function (event) {
      if ($(this).attr('href') === '#') {
        return event.preventDefault();
      }
    });
}

module.exports = simpleTooltip;
